@font-face {
	font-family: 'AvenirLTStd-Book';
	src: url("/fonts/AvenirLTStd-Book.otf") format("opentype");
}

* {
	font-weight: 300;
	margin: 0;
	font-family: 'AvenirLTStd-Book', 'Roboto light';
}



.maxWidthPage {
	width: 100%;
}

.resultsSearchContainer{
	font-weight:500;
}
.autoCenter {
	position: relative;
	max-width: 100%;
	margin: auto;
}

a {
	text-decoration: none;
	color: inherit;
}

i.icon-chevron-left.icon-white, i.icon-chevron-right.icon-white {
	font-size: 25px;
	color: rgb(242,242,242);
}

.owl-prev {
	position: absolute;
	top: 70px;
	left: 0px;
}

.owl-next {
	position: absolute;
	top: 70px;
	right: 0px;
}
.owl-item.loading{
	min-height: 150px;
	background: url(/home/bendodi/romeo/immo/sources/carousel/public/AjaxLoader.gif) no-repeat center center
}


@media screen and (min-width: 1035px){

	.resultContainer {
		background-image: url("https://res.cloudinary.com/serpoma/image/upload/v1584720789/relief2.png");
		padding: 0 0 0 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: auto;
		position: relative;
		
		
	}


}

@media screen and (max-width: 1035px){

	.resultContainer {
		background-image: url("https://res.cloudinary.com/serpoma/image/upload/v1584720789/relief2.png");
	
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: auto;
		position: relative;
		
		
	}


}


.searchResultsContainer {
	width: calc(100%);
	background-image: url("https://res.cloudinary.com/serpoma/image/upload/v1584720789/relief2.png");

	
}

.searchResultsContainerDetail {
	border-radius: 10px;
}





nav.header {
	display: flex;
	justify-content: space-between;
	align-items:  center;
}

.viewContainer {
	min-height: calc(100vh - 40px)
}

#headerContainer {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
	padding: 0px 10px;
	height: 50px;
	background-color: white;
	box-shadow: 1px 1px 20px 1px #d6d6d6;
}

.logoHeader {
	width: 150px;
	font-size: 25px;
}

a.navbarButton {
    display: flex;
    flex-direction: column;
    align-items: center;
}

nav>a {
	margin-left: 15px;
	color: #056AB3;
	text-decoration: none;
	padding: 10px;
}

nav>a:hover {
	color: rgb(255,218,0);
}

.navTelNumber {
	margin-left: 5px;
	color: #056AB3;
	cursor:pointer;
}

.trustSearchButton {
	background-color: rgb(255,218,0);
	padding: 10px 20px;
	border: none;
	margin: auto;
	margin-left: 20px;
	font-weight: 400;
	font-size: 18px;
	border-radius: 10px;
	color: #056AB3;
	text-align: center;
}

.trustSearchButton:hover {
	color: #056AB3;
}

.explanationButton {
	background-color: rgb(255,218,0);
	color: #056AB3;
	border-radius: 10px;
}

.trustSearchButton.size {
	width: 200px;
	margin-top: 10px;
	display: block;
	text-align: center;
	margin: auto;
}

.electricButtonContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.electricButton {
	background-color: rgb(255,218,0);
	padding: 7px;
	border: none;
	width: 300px;
	margin: 50px;
	color: #056AB3;
	font-size: 20px;
	font-weight: bold;
	border-radius: 10px;
}

.homeCoverOverlay {
	display: flex;
	text-align: center;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 100px;
}

.homeMessage {
	font-size: 50px;
	font-weight: bold;
	color: #056AB3;
	width: 100%;
}


.coverPictures {
	min-height: 100vh;
	height: min-content;
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
	background-position-x: center;
	background-position-y: center;
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    object-fit: fill;
    margin-top: 40px;
}

.sloganHomeMessage {
    color: #056AB3;
    font-size: 21px;
    font-style: italic;
    padding-bottom: 20px;
    padding:20px;
}

.loupe {
	width: 15px;
	height: 15px;
}

.searchContainer {
	background-color: rgba(250,250,250,0.8);
	width: 60%;
	border-radius: 10px;
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	padding: 10px 0px;
	margin: auto;
	position: relative;
}

.inputSearchContainer {
	display: flex;
	flex-wrap: wrap;
}

.backgroundHomePage {
	background-color: white;
}

.maxWidthPage {
	background-color: rgb(242,242,242);
}

.viewContainer {
	background-color: white;
}

.inputSearch {
	border: none;
	border-radius: 5px;
	height: 40px;
	padding: 5px;
	margin: 5px;
	width: 130px;
	font-size: 16px;
}

.btn {
  width: 200px;
  height: 40px;
  color: #056AB3;
  background-color: rgb(255,218,0);;
  border-radius: 10px;
  box-shadow: 0px 3px 0px rgb(240,200,70);
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  -webkit-animation: btnWiggle 2s infinite;
  -moz-animation: btnWiggle 2s infinite;
  -o-animation: btnWiggle 2s infinite;
  animation: btnWiggle 2s infinite;
}

@-webkit-keyframes btnWiggle {
    0% {-webkit-transform: rotate(0deg);}
    2% {-webkit-transform: rotate(-1deg);}
    3.5% {-webkit-transform: rotate(1deg);}
    5% {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(0deg);}
}
@-o-keyframes btnWiggle {
    0% {-webkit-transform: rotate(0deg);}
    2% {-webkit-transform: rotate(-1deg);}
    3.5% {-webkit-transform: rotate(1deg);}
    5% {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(0deg);}
}
@keyframes btnWiggle {
    0% {-webkit-transform: rotate(0deg);}
    2% {-webkit-transform: rotate(-1deg);}
    3.5% {-webkit-transform: rotate(1deg);}
    5% {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(0deg);}
}

.firstContainer {
	min-height: 100vh;
	width: 100%;
}

/*.purchaseExplanation {
	padding: 10px;
}

.purchaseBenefits {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around; 
	margin-top: 30px;
}

.purchaseBenefitsRight, .purchaseBenefitsLeft {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	height: 80%;
}*/
.purchaseBenefitsRight {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	margin-top:50px;
}

.purchaseExplanation {
	width: 250px;
	padding: 20px;
}

.howToBuyExplanationTitle {
	display: flex;
	align-items: center;
	margin: auto;
	width: 85%;
	text-align: left;
}

.avantagesCatchPhrase {
	text-align: center;
	padding: 100px;
	font-size: 35px;
}

.explanationPhrases {
	text-align: center;
}

h3 {
	padding-left: 10px;
	font-weight: 400;
}

i.fa.fa-car, i.fa.fa-credit-card {
	font-size: 70px;
	margin-bottom: 20px;
	color: rgb(0,32,96);
}

i.fa.fa-address-card, i.fa.fa-check-circle {
	font-size: 70px;
	margin-bottom: 20px;
	color: rgb(255,218,0);
}



.faCarDescription {
	display: flex;
	justify-content: space-around;
}

.carDescriptionBoxes {
	margin-bottom: 10px;
	margin-top: -15px;
}

.icons {
	text-align: center;
}

.blueSpan {
	color: rgb(0,32,96);
	font-weight: bold;
}

.orangeSpan {
	color: rgb(255,218,0);
	font-weight: bold;
}


footer {
	color: rgba(255,255,255,0.73);
	width: 100%;
	background-color: white;
}

.footerFollow { 
	background-color: #056AB3;
	border: none;
	padding: 10px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.newsletterFooter {
	display: flex;
	text-align: center;
	padding: 10px 0px;
	align-items: center;
}

i.fa.fa-search.footer {
	color: white;
}

.newsletter {
	display: inline-block;
	margin-right: 40px;
	color: white;
	font-weight: bold;
	margin-left: 7px;
}

#mailNewsletter {
	padding: 6px;
    vertical-align: middle;
    line-height: 16px;
    width: 200px;
}
#formContainer i {
	padding-right: 10px;
}

.mailNewsletter {
	display: inline-block;
}

.submitNewsletter {
	background-color: rgb(255,218,0);
	color: white;
	border: none;
	cursor: pointer;
	font-weight: bold;
	padding: 5px;
	transition: 400ms;
}

.submitNewsletter:hover {
	background-color: rgb(249,100,30);
}

.smallView {
	height: initial;
	padding: 8px;
	display: inline-block;
	vertical-align: middle;
    line-height: 16px;
}


.followUs {
	display: inline-block;
}

.span2 {
	font-family: 'Woodford Bourne';
	text-transform: uppercase;
}

.footerIcon {
	color: rgba(255,255,255,0.73);
	margin-left: 19px;
	font-size: 20px;
	cursor: pointer;
	transition: 250ms;
}

.footerIcon:hover {
	transform: scale(1.2);
	color: rgb(255,218,0);
}

#footerList {
	background-color: white;
	padding-bottom: 30px;
}

.authorLink {
	background-color: white;
	text-align: center;
	padding-bottom: 30px;
	font-size: 13px;
	color: #056AB3;
}

.authorLink a {
	text-decoration: none;
	color: #056AB3;
}

.footerP {
	margin-top: 20px;
	margin-bottom: 10px;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

#footerList a:hover {
	text-decoration: underline;
}

.viewContainer {
	min-height: calc(200vh - 400px);
}

#footerList a {
	text-decoration: none;
	color: #056AB3;
}

.HiddenModelIdContainer {
	display: none;
}

.optGroupLabel, .selectedOption {
	font-weight: 400;
}

.phoneIconImg {
	width: 15px;
	height: 15px;
}

#input-numberMin, #input-numberMax  {
	padding: 7px;
	margin: 15px 5px 5px;
	width: 70px;
}

.phoneIconNav {
	display: flex;
	align-items: center;
	width: 140px;
	margin-left: 30px;
}

.secondContainer {
	width: 100%;
}

.catchPhrase {
	text-align: center;
	padding: 40px 0px;
	font-size: 25;
}

.catchWords {
	font-weight: 400;
}

.logoFooter {
	height: 50px;
	text-align: center;
	background-color: white;
}

#menuIcon {
	display: inline-block;
	color: white;
	font-size: 30px;
}

.menuIcon {
	color: white;
	font-size: 25px;
	cursor: pointer;
	display: none;
}

.imageFooter {
	height: 40px;
}

.homeCoverOverlay.admin {
	margin-top: 10px;
}

.homeMessage.admin {
	font-size: 40px;
	margin: 20px 0px;
}

.inputSearch.admin {
	display: flex;
	justify-content: center;
	width: 300px;
	margin: auto;
	margin-top: 10px;
}

.searchContainer.admin {
	width: 900px;
	display: flex;
	justify-content: space-around;
	background-color: rgba(250,250,250,0.3);
	min-height: fit-content;
	padding: 30px;
}

.htsDescriptionIconContainer {
	display: flex;
	justify-content: center;
	align-items: baseline;
	text-align: justify;
}

.htsCheckDescription {
	width: 80%;
	margin-left: 10px;
}

i.fa.fa-check {
	color: #056AB3;
}

.addCarTitles {
	font-size: 30px;
	color: white;
	margin: 20px 0px;
}

.backgroundAdmin {
	background-color: #056AB3;
}

.textarea.admin {
	height: 350px;
	width: 320px;
	margin-bottom: 10px;
}

.secondContainer.admin {
	padding-bottom: 60px;
}

.addCarButton {
	background-color: rgb(255,218,0);
	cursor:pointer;
	font-weight: bold;
}

.optionCheckbox {
	display: flex;
	justify-content: space-around;
	padding: 5px;
}

.labelAmenities {
	width: 200px;
	color: white;
}

.amenities {
	width: 18px;
	height: 18px;
}

.generalInformation {
	align-items: center;
}

.amenityTitle {
	color: rgb(255,218,0);
	font-size: 20px;
	font-weight: 400;
	padding: 20px;
	text-align: center;
}

.searchMenuContainer {
	display: flex;
	justify-content: space-between;
	min-height: 100vh;
}

.researchContainer {
	width: 300px;
	position: relative;
	background-color: rgb(242,242,242);
	padding-top: 10px;
}

.researchMenu {
	margin: auto;
	width: 250px;
	position: relative;
	border-radius: 20px;
	border: solid 0.5px rgb(58,61,89);
	background-color: white;
	padding-bottom: 20px;
	box-shadow:2px 2px 10px gray;
}

.inputSearch.searchResults {
	width: 200px;
	min-height: 30px;
	margin-top: 3px;
}

.searchRefine {
	background-color: #056AB3;
	border-radius: 20px 20px 0px 0px;
	font-size: 20px;
	color: white;
	padding: 8px;
	text-align: center;
	font-weight: 400;
}

.dreamCar {
	background-color: rgb(58,61,89);
	border-radius: 20px 20px 0px 0px;
	font-size: 15px;
	color: white;
	padding: 10px;
}

.inputSearch.Research {
	margin: auto;
	width: 210px;
	background-color: white;
	height: 30px;
}



.carVersion {
	font-weight: bold;
	padding: 5px;
	text-align: center;
}

.carVersion.searchResults {
	width: 100%;
}

.flexboxButton {
	display: flex;
	justify-content: center;
}

.magnifyingGlass {
	margin-left: 10px;
}

.carResultContainer {
	width: 360px;
	height: fit-content;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 15px;
	padding-right: 0px;
	padding-top: 0px;
	cursor: pointer;
	transition: 250ms;
	box-shadow:0px 0px 0px gray; 
	border-radius: 0%;
}

.howToSellExplanations {
	width: 100%;
}

.carResultContainer:hover {
	transform: scale(1.02);
}

.carDescriptionBoxes {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

.noUi-toolt {
	display: block;
    position: absolute;
    padding: 2px;
    text-align: center;
    white-space: nowrap;
}

.carPrice {
	color: #056AB3;
	font-size: 20px;
	font-weight: bold;
}

.loupeLePiston {
	width: 10px;
	width: 10px;
	background-color: inherit;
}

.newsletterContainer {
	display: flex;
	align-items: center;
}

.carInformation {
	font-weight: 400;
	display: flex;
	align-items: center;
	margin-top: 2px;
	margin-bottom: 2px;
}

.carPriceDescription {
	margin-top: 5px;
}

.iconSearchResults {
	width: 17px;
	margin: 0px 10px;
	color: rgb(110, 113, 116)
}

.iconDescription {
	text-align: left;
	margin-top:30px;
	margin-left: 10px;
	margin-right: 10px;
	padding-top: 2px;
	font-size: 1.985rem;
	line-height: 1.231;
    letter-spacing: .015em;
	font-family: "HelveticaNeueW02-47LtCn 694048",Arial;
}

.carsPictures {
	height: 270px;
	width: 270px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position-x:center;
	/*background-position-y:center;*/
	z-index: 0;
}

.carProfilePictures {
	height: 450px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position-x:center;
	/*background-position-y:center;*/
	z-index: 0;
	position: relative;
}

.carProfileContainer.carProfile {
	width: 100%;
	margin: 0px;
}

.owlCarouselSize {
	width: 270px;
	height: 205px;
	z-index: 0;
}

.carProfileOwlCarouselSize {
	width: 100%;
	height: fit-content;
	z-index: 0;
}

.sellFormContainer {
	width: 600px;
	margin: auto;
	position: relative;
	min-height: fit-content;
	background: rgba(255,255,255,0.9);
	padding: 15px;
	border-radius: 10px;
	margin-top: 7%;
	/*border: solid 1px rgb(255,218,0);
*/}

.sellFormContainer.contact {
	margin-top: 15px;
}

.fullName {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.sellTitle {
	font-size: 30px;
	color: rgb(0,32,96);
	margin-bottom: 20px;
}

.oupsTitle {
	padding: 20px;
	font-size: 17px;
}

.inputFullName {
	width: 240px;
	padding: 5px;
	margin: 0px 10px;
	height: 50px;
	background-color: inherit;
	border: 0.5px solid rgb(0,32,96);
	border-radius: 10px;
	font-size: 16px;
}

.sell {
	width: 500px;
	padding: 5px;
	height: 50px;
	background-color: inherit;
	margin-top: 10px;
	border: 0.5px solid rgb(0,32,96);
	border-radius: 10px;
	font-size: 16px;
}

.sellButton {
	width: 500px;
	border: none;
	height: 40px;
	padding: 5px;
	margin: 5px;
	height: 50px;
	color: #056AB3;
}

.successFormContainer {
	width: 60%;
	margin: auto;
	position: relative;
	height: fit-content;
	background: rgba(255,255,255,0.9);
	margin-top: 100px;
	border: solid 1px rgb(255,218,0);
	padding: 10px;
}

.oupsContainer {
	width: 100%;
}

.oupsBox {
	width: 50%;
	margin: auto;
	position: relative;
	height: fit-content;
	background: rgba(255,255,255,0.9);
	margin-top: 20px;
	border: solid 1px rgb(255,218,0);
}

.acceptedCGU {
	font-size: 12px;
	padding: 20px;
}

.CGU {
	color: rgb(5,106,179);
	cursor: pointer;
}

.htsTitle {
	color: rgb(5,106,179);
	font-size: 35px;
	text-align: center;
	padding: 70px;
	font-weight: bold;
}

.drivingWheels {
	display: flex;
}

.backgroundColor {
	background-color: rgb(242,242,242);
	width: 100%;
}

.backgroundColorGrey {
	width: 100%;
	height: 50px;
	background-color: rgb(242,242,242);
}

.htsBox {
	background-color: rgba(255,255,255,0.7);
	width: 90%;
	min-height: fit-content;
	margin: auto;
	border-radius: 40px;
	border: solid 2px rgb(255,218,0);
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	padding: 40px 10px;
	margin-bottom: 120px; 
}

.htsBox.blueBox {
	background-color: rgb(238,238,238);
}

.electricBox {
	background-color: rgba(255,255,255,0.95);
	width: 80%;
	min-height: 230px;
	margin: auto;
	border-radius: 40px;
	border: solid 2px rgb(255,218,0);
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 40px;
}

.electricExplanation {
	text-align: left;
	font-size: 20px;
	color: grey;
	margin-left: 10px;
}

.iconBox {
	display: flex;
	flex-direction: column;
	width: 190px;
	align-items: center;
	margin-top: 15px;
}

.iconImage {
	width: 100px;
}

.iconTitle {
	color: rgb(5,106,179);
	font-weight: bold;
	padding: 20px 0px;
}

.explanationTitle {
	color: rgb(5,106,179);
	font-weight: 400;
	padding: 10px;
	font-size: 25px;
	margin-left: 15px;
}

.explanationBox {
	width: 100%;
	min-height: 350px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.explanationBox.white {
	background-color: white;
}

.explanationBox.grey {
	background-color: rgb(238,238,238);
}

.explanationContainerTitle {
	color: rgb(5,106,179);
	font-size: 25px;
	text-align: center;
	font-weight: bold;
	padding: 20px;
	background-color: white;
}

.explanationButton {
	color: #056AB3;
	font-size: 22px;
	padding: 10px;
	background-color: rgb(255,218,0);
	font-weight: bold;
	width: 300px;
	text-align: center;
	margin: auto;
	margin-top: 50px;
	text-decoration: none;
	border-radius: 10px;
}

.htsExplanation {
	width: 60%;
	line-height: 1.3;
	margin: auto;
}

.htsPictureContainer {
	display: flex;
	width: 40%;
	max-height: 450px;
}

.htsPicture {
	width: fit-content;
	margin: auto;
}

.htsPicture.size {
	width: 70%;
	height: 70%;
}

.htsPicture.reverseImmage {
	width: 250px;
}

.faIconsBlue {
	color: rgb(5,106,179)
}

.htsDescription {
	text-align: justify;
	width: 85%;
	margin: auto;
}

.contactTextarea {
	height: 200px;
	margin-bottom: 20px;
}

.srTitle {
	color: #056AB3;
	font-size: 40px;
	text-align: center;
	padding-bottom: 30px;
	font-weight: bold;
	padding-left:10px;
	padding-right: 10px;
	padding-top: 20px;
}

.yellowTitle {
	color: rgb(255,218,0);
	font-size: 60px;
	text-align: center;
	font-weight: bold;
	padding-left:10px;
	padding-right: 10px;
	padding-top: 40px;
}

i.fa.fa-check.yellow {
	color: rgb(255,218,0)
}

.titleNumber {
	width: 30px;
}

.howToBuyOrSell {
	width: 180px;
	height: 100px;
	position: absolute;
	top: 50px;
	background: white;
	right: 480px;
	text-align: center;
	display: none;
}

.howToButton {
	color: #056AB3;
	padding: 15px;
	border: solid 1px white;
}

.howToButton:hover {
	border-bottom-color: #056AB3;
}

.searchResultsBanner.coverPictures.noMargin {
	margin: 0px;
}

.profilContainer {
	background-color: rgb(242,242,242);
	padding: 10px 0px 0px 10px;
}

.inspectionDescriptionBottom {
	margin-top: 20px;
}

.carProfileContainer {
	width: 100%;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 10px;
	padding: 10px 0px;
}

.descriptionContainer {
	min-height:fit-content;
	background-color: white;
	padding: 10px;
}

.descriptionContainerProfile {
	margin-left: 30px;
}

.phoneLePiston {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 10px; 
}

.carProfileWidgetTitle {
	text-align: center;
	background-color: #056AB3;
	border-radius: 10px;
	margin: 5px;
	color: white;
	font-weight: 400;
	padding: 5px;
}

.iconPhone {
	width: 40px;
	height: 40px;
}

.phoneNumberLePiston {
	font-size: 22Px;
	color: #056AB3;
	margin: 10px 15px;
	font-weight: 400;
}

.carProfileWidgetContainer {
	background-color: #056AB3;
	padding: 15px;
	border-radius: 20px;
}

.searchResults.carProfile {
	height: 20px;
	padding: 5px;
	border: none;
	margin: 7px;
	width: 200px;
	height: 30px;
	border-radius: 5px;
}

.contentContainer {
	width: 100%;
	margin: auto;
}

.inputSearch.searchResults.addCarButton.flexboxButton.carProfile {
	background-color: rgb(255,218,0);
	font-size: 20Px;
	color: #056AB3;
	height: 45px;
}

.inputSearch.searchResults.addCarButton.flexboxButton {
	height: fit-content;
	padding: 10px;
}

.magnifyingGlass, i.fa.fa-search {
	color: #056AB3;
}

.searchResultsContainer.textarea.carProfile {
	width: 200px;
	height: 70px;
	margin-left: 6px;
	margin-bottom: 7px;
	background-color: rgb(255, 255, 255);
	padding: 5px;
	border-radius: 5px;
}

.researchMenu.carProfile {
	padding: 10px;
}

.carVersion.carProfile {
	font-size: 25px;
}

.headCarProfileBox {
	display: flex;
	justify-content: space-around;
}

.carPrice.carProfile {
	font-size: 30px;
}

.carFeatures {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	width: 100%;
	margin-top: 20px;
}

.downloadInspectionReport {
	color: white;
	background-color: #056AB3;
	padding: 10px;
	border-radius: 10px;
	cursor: pointer;
}

.carGeneralDescription {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.generalDescription {
	display: inline-flex;
	width: 50%;
	align-items: center;
	flex-wrap: wrap;
}

.generalInformationTitleContainer {
	display: flex;
	justify-content: left;
	align-items: center;
}

.generalInformationIcon {
	width: 50px;
	height: 50px;
	margin-right: 15px;
}

.generalInformationTitle {
	font-size: 23px;
	color: #056AB3;
	font-weight: bold;
	text-decoration: underline;
}

.carVersionItem {
	font-size: 20px;
	font-weight: bold;
	padding: 20px 0px;
}

.carVersionFlexBox {
	display: flex;
	flex-wrap: wrap;
	justify-content: left;
	margin-left: 30px;
	margin: auto;
}

.itemDescription {
	font-weight: 600;
	width: 205px;
}

.searchResults.carProfile.transform {
	font-size: 16px;
	width: 200px;
}

.optionItem {
	font-size: 16px;
	width: 300px;
	margin: 2px 10px;
}

.downloadInspectionReport.inspection {
	width: 300px;
	text-align: center;
	margin: 10px;
}

i.fa.fa-chevron-left, i.fa.fa-chevron-right {
	font-size: 25px;
	color: rgb(242,242,242);
}

i.fa.fa-chevron-right.searchResult.blue, i.fa.fa-chevron-left.searchResult.blue {
	color: #056AB3;
	font-size: 35px;
}

.textSize {
	font-size: 19px;
}

.customPrevBtn {
	position: absolute;
	top: 66px;
}

.customNextBtn {
	position: absolute;
	top: 66px;
	right: 0px;
}

.customPrevBtnCarProfile {
	position: absolute;
	top: 50%;
	left: 15px;
	cursor: pointer;
}

.customNextBtnCarProfile {
	position: absolute;
	top: 50%;
	right: 15px;
	cursor: pointer;
}

.mainPrice {
	border:0;
	color:#056AB3;
	font-weight:bold;
	background-color: inherit;
	width: 100%;
	text-align: center;
	font-family: 'AvenirLTStd-Book';
	font-size: 16px;
}

.priceLabelInput {
	width: 40%;
	margin-top:30px;
}

.btn.inputSearch.textSize {
	margin-top:30px;
}

.priceLabelInput.searchResults, .drivingWheels {
	width: 70%;
	position: relative;
	margin: auto;
}

.drivingWheels {
	margin-top: 40px;
	margin-bottom: 20px;
}

.searchCheckbox {
	margin-left: 20px;
	height: 18px;
	width: 18px;
}

.heart {
	color: inherit;
}

.carPriceDescription:hover::before {
	content: "négociable avec le bailleur.";
	position: absolute;
	right: 0px;
	bottom: 10px;
	font-size: 13px;
	background-color: white;
}

.navbarButton.hiwButton:hover::before {
	width: 180px;
	height: 100px;
	position: absolute;
	top: 50px;
	background: white;
	right: 450px;
	text-align: center;
}

.priceLabelInput.searchResults {
	margin-top: 37px;
}

.trustSearchButton.size.success {
	margin: auto;
	background-color: white;
}

.returnButton {
	margin-top: 15px;
}

.advancedResearchButton, .advancedResearchButtonCarProfile, .refreshCarProfile, .mobileSearch, .refreshSearchResult {
	display: none;
	cursor: pointer;
	font-weight: bold;
	font-size: 17px;
	box-shadow: 2px 2px 10px grey;
}

.mobileSearch {
	font-size: 14px;
}

#instancesTable {
	border-collapse: collapse;
	width: 300px;
	margin-top: 20px;
}

#instancesTable td, #instancesTable th {
	border: 1px solid #ddd;
	padding: 8px;
}

#instancesTable tr:nth-child(even) {
	background-color: #f2f2f2;}

#instancesTable tr:hover {
	background-color: #ddd;}

#instancesTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #056AB3;
    color: white;
}

.DriveWheels, .IsSold {
	height: 18px;
	width: 18px;
}

.carPriceBox {
	width: 40%;
}

.paymentSpreadResults {
	width: 80%;
}

tr {
	cursor: pointer;
}

.inputSell.space {
	margin: 20px 0px;
}

.searchResults.textarea.carProfile {
	width: 200px;
	height: 100px;
}

.buyACarByBrandContainer {
	width: 100%;
	margin-top: 30px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;
}

.priceLabelInputSearchResults {
	width: 180px;
	margin: auto;
	margin-top: 30px;
}

.buyByBrand {
	display: block;
	width: 300px;
	margin: auto;
	text-align: left;
}

.buyByBrand:hover {
	text-decoration: underline;
}

.pSell {
	padding: 10px;
}

.responsiveCenter {
	width: 100%;
	height: 100vh;
}

.isSoldBanner {
	text-align: center;
    padding: 5px 0px;
    width: 100%;
    background-color: rgba(5,106,179,0.6);
    padding-right: 10px;
    font-size: 15px;
    color: white;
    font-weight: bold;
    position: relative;
    top: -55px;
    z-index: 2;
}

/* Média Queries*/

@media screen and (max-width: 1105px){
	.menuIcon {
		display: inherit;
	}

	nav.header {
		display: none;
		height: fit-content;
		background-color: white;
		width: 250px;
		position: fixed;
		top: 39px;
		right: 0px;
		z-index: 2;
		border: solid 1px rgb(170,170,170);
		padding: 10px;
	}

	header a {
		margin: inherit;
		padding: 5px;
		display: flex;
		flex-direction: column;
	}

	.howToBuyOrSell {
		position: static;
		background: white;
		display: none;
		height: 60px;
	}

	.howToButton {
		color: rgb(255,218,0);
		text-align: right;
		padding: 0px;
	}

	.howToButton:hover {
		border-bottom-color: #056AB3;
	}

	.footerBox {
		padding: 0px 20px;	
	}	

	.menuIcon {
		padding-right: 20px;
		display: flex;
		flex-direction: column;
		margin-bottom: 19px;
	}

	.menuIcon.isExpanded .blackMenu:first-child {
	    transform: rotate(45deg) translate3d(.125rem,.125rem,0);
	}

	.menuIcon.isExpanded .blackMenu:nth-child(2) {
	    transform: rotate(-45deg) translate3d(.125rem,-.125rem,0);
	}

	div#headerBox {
		display: none;
	}

	.logoHeader {
		width: 100px;
	}

	.blackMenu {
	    width: 2.25rem;
	    height: 1.5rem;    
   		margin: 0px 0px -17px;
   		transition: transform .25s cubic-bezier(0.25, 0.1, 0.22, 0.89);
	}

	nav>a {
		margin-top: 10px;
		padding:  0px 0px 10px 0px;
		margin-left: 0px;
	}

	.homeCoverOverlay {
		margin-top: 80px;
	}

	.homeMessage {
		margin-top: 20px; 
	}

	.homeCover {
		width: 100%;
	}

	.searchContainer {
		width: 500px;
	}

	.priceLabelInput {
		width: 180px;
		margin:auto;
		margin-top: 30px;
	}

	.htsExplanation, .howToBuyExplanationTitle, .htsPictureContainer {
		width: 80%;
	}

	.htsDescription {
		width: 80%;
		margin: auto;
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 900px){
	.researchContainer.carProfile {
		display: none;
	}

	.searchResultsContainer {
		width: 100%;
	}

	.carProfilePictures {
		height: 400px;
	}
}

@media screen and (max-width: 700px){
	.homeMessage {
		font-size: 30px;
	}	

	.sloganHomeMessage {
		font-size: 18px;
	}

	.homeCoverOverlay {
		margin-top: 10px;
	}

	.inputSearch {
		display: block;
		width: 200px;
	}

	.searchContainer {
		width: 250px;
	}

	.footerP {
		justify-content: center;
	}

	#footerList a {
		width: 250px;
		text-align: center;
		margin-top: 10px;
	}

	.starredImage {
		width: 170px;
		margin:auto;
	}

	.sell, .inputFullName {
		width: 300px;
	}

	.fullname {
		display: block;
	}

	.inputFullName {
		margin-top: 10px;
	}

	.sellFormContainer {
		width: 400px;
	}

	.sellButton {
		width: 300px;
		margin: auto;
	}

	.resultContainer {
		background-color: white;
	}

	.researchContainer {
		display: none;
	}

	.researchMenu {
		margin-bottom: 100px;
		padding-bottom: 30px;
	}

	.advancedResearchButton, .advancedResearchButtonCarProfile {
		display: block;
		position: fixed;
		bottom: 0px;
		width: 100%;
		padding: 20px 0px;
		z-index: 100;
		background-color: rgb(255,218,0);
		color: rgb(5,106,179);
		text-align: center;
	}

	.mobileSearch {
		display: block;
		position: fixed;
		top: 40px;
		width: 100%;
		padding: 5px;
		z-index:100;
		background-color: rgb(5,106,179);
		color: white;
		text-align: center;
	}

	.refreshSearchResult {
		position: fixed;
		top: 40px;
		width: 100%;
		padding: 5px;
		z-index:100;
		background-color: rgb(5,106,179);
		color: white;
		text-align: center;
	}

	.refreshCarProfile {
		display: block;
		position: fixed;
		bottom: 0px;
		width: 100%;
		padding: 20px 0px;
		z-index: 100;
		background-color: rgb(255,218,0);
		color: rgb(5,106,179);
		text-align: center;
	}

	.electricExplanation {
		font-size: 15px;
	}

	.electricbox {
		margin-top: 20px;
	}

	.electricbutton {
		margin: 20px;
		padding: 5px;
	}

	.carProfilePictures {
		height: 300px;
	}

	.researchMenu.carProfile {
		width: 90%;
		margin:auto;
	}

	.searchResults.textarea.carProfile, .searchResults.carProfile.transform, .inputSearch.searchResults.addCarButton.flexboxButton.carProfile {
		width: 100%;
		margin:auto;
		margin-bottom: 10px;
	}
}

@media screen and (max-width: 600px){
	.searchLinkContainer {
		width: 100%;
	}

	.carResultContainer {
		margin: auto;
		margin-bottom: 15px;
	}
}

@media screen and (max-width: 450px){
	.sell, .inputFullName {
		width: 210px;
	}

	.fullname {
		display: block;
	}

	.inputFullName {
		margin-top: 10px;
	}

	.sellFormContainer {
		width: 240px;
	}

	.sellButton {
		width: 210px;
		margin: auto;
	}

	.htsPicture.size {
		width: 300px;
	}

	.explanationButton {
		width: 200px;
	}

	.downloadInspectionReport.inspection {
		width: 150px;
		margin: 0px;
		margin-top: 15px;
	}

	.downloadInspectionReport {
		width: 300px;
		margin-top: 10px;
	}

	.descriptionContainerProfile {
		margin-left: 5px;
	}

	#instancesTable {
		width: 100%;
	}

	.carProfilePictures {
		height: 180px;
	}

	.carVersion.carProfile {
		font-size: 20px;
	}
	.carPrice.carProfile {
		font-size: 25px;
	}

	.carProfileContainer {
		padding: 0px;
	}

	.researchMenu.carProfile {
		margin: 0px;
	}

	.yellowTitle {
		font-size: 30px;
	}
}