body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.x{
  margin-top: 70px;
  
}
.body_campagne{
  margin-bottom: 20px;
}
.r_d{
  background-color:#eee;
}
.k{
  margin-top: -10px;
  margin-bottom: 20px;
}
.j{
  margin-top: 100px;
}
.dev_header{
  margin-top: 80px;
  margin-bottom: 20px;
  
}
.dev_header h2{
  text-align: center;
  font-weight:normal!important;
  font-size:35px;
  font-family:'Frutiger W01', 'Open Sans', sans-serif;
  line-height:40px;
  padding-top: 20px;
}
.dev_description {
  font-size:20px;
  font-family:"Roboto", "Helvetica", "Arial", sans-serif;
  font-weight:400;
  line-height:1.5;
  letter-spacing:0.00938em;
  color:rgba(0, 0, 0, 0.54);
  padding-left: 40px;
  padding-right: 10px;
}
.dev_background{
  background-color: #fff;
  margin-bottom: 10px;
}
.backgroundApp{
  background-color: #eee;
}
.mediaCarContainer{

  display: flex;
  flex-direction: column; 
  border:2px solid #ececec;
  /* border-bottom: 2px solid #df0c36; */
  border-bottom: 2px solid #1890ff;
  margin: auto;
  width: 100%;
  /*width: 33.33%;
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  width: 31,85%;*/
  
}

/*.mediaCardImage{
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
}*/

.mediaCarBody{
  font-family: Arial;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #666;
    padding: 5px;
}
.mediaCarFooter{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 15px;
  padding-right: 10px;
  padding-bottom: 15px;
}
.mediaCarTitle{
  padding-top: 15px;
}
.mediaCarTitle h2{
  padding: 5px;
  font-size:1.1875rem;
  line-height:1.1875rem;
  font-family:"helveticaneuew02-77bdcn 694066","arial bold,sans-serif";
  display: flex;
  color: #3BB9FF;
  justify-content: center;
  align-items: center;
}
.title_Produit h2{
  padding: 5px;
  font-size:2.1875rem;
  line-height:1.1875rem;
  font-family:"helveticaneuew02-77bdcn 694066","arial bold,sans-serif";
  display: flex;
  color: #69C7E5;
  justify-content: center;
  align-items: center;
}
.date{
  /* background: #df0c36; */
  background-color:#3BB9FF;
  color:#fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}
.x{
 display: flex;
 flex-wrap: wrap;
 width: 100%;
}
.image{
  width: 100%;
}
.button{
  background-color:#3BB9FF;
  color:white;
  border: none;
  border-radius: 25px;
}
.Contenaire_Mediacards{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin-bottom: 20px;
  padding-top: 10px;
 /*  height: 800px; real browsers */
}

a:link {
  color: green;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color:#0d6dae;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: red;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: yellow;
  background-color: transparent;
  text-decoration: underline;
}
.pagination_actualite{
  margin-top: 10px;
}
.photo{
  width: 60%;
}
.divider{
  border-top: 10px solid #3BB9FF;
  margin: auto;
}
.partenaire{
  padding-top: 70px;
}
.content_partenaire{
  margin-top: 10px;
  margin-bottom: 10px;
}